
import { Vue, Component, Prop } from 'vue-property-decorator'
interface Detail {
  deviceNumber: string;
  deviceName: string;
}
@Component
export default class SoilDialog extends Vue {
@Prop({ default: false }) showDialog!: boolean
@Prop({ default: { deviceNumber: '' } }) detail!: Detail
  private formInfo = {}

  created () {
    //
  }

  onSubmit () {
    ;(this.$refs.formInfo as any).validate((valid: any) => {
      if (valid) {
        this.formInfo = {
          deviceNumber: this.detail.deviceNumber,
          deviceName: this.detail.deviceName
        }
        this.$axios.post(this.$apis.smartHouse.updateSqIrrigationValveName, this.formInfo).then((res) => {
          this.$message.success('设置成功')
          this.$emit('success')
          this.closeDialog()
        })
      }
    })
  }

  closeDialog () {
    this.$emit('update:showDialog', false)
  }
}
