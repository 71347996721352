
import { Vue, Component } from 'vue-property-decorator'
import SoilList from './soilList.vue'
import SolenoidList from './solenoidList.vue'
import WaterList from './waterList.vue'

  @Component({
    components: {
      SoilList,
      SolenoidList,
      WaterList
    }
  })
export default class IrrigationTimingPlan extends Vue {
    currentIndex = 0
    list = [{
      name: '土壤监测',
      url: SoilList
    },
    {
      name: '智慧水表',
      url: WaterList
    },
    {
      name: '电磁阀',
      url: SolenoidList
    }]

    currentComponent = SoilList

    created () {
      if (this.$route.query.deviceIndex) {
        this.currentIndex = +this.$route.query.deviceIndex
        this.currentComponent = this.list[this.currentIndex].url as any
      }
      // 1
    }

    toggleComponent (index: number) {
      this.currentIndex = index
      this.currentComponent = this.list[index].url as any
      this.$router.push({
        path: '/smartHouse/device',
        query: { deviceIndex: this.currentIndex.toString() }
      })
    }
}
