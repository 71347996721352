
import { Component, Vue } from 'vue-property-decorator'
import SolenoidDialog from './solenoidDialog.vue'

  @Component({
    name: 'SuppliermanageList',
    components: {
      SolenoidDialog
    }
  })
export default class extends Vue {
    private searchInfo = {
      deviceNumber: '',
      deviceName: ''
    }

    private page = 1
    private size = 10
    private total = 0
    private tableData =[]
    private loading = false
    private showDialog = false
    private detail = {}

    created () {
      this.getData()
    }

    // 查询
    searchData () {
      this.page = 1
      this.getData()
    }

    // 获取表格数据
    getData () {
      this.loading = true
      this.$axios.get(this.$apis.smartHouse.selectSqIrrigationValveByPage, {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then((res) => {
        this.total = res.total || 0
        this.tableData = res.list || []
      }).finally(() => {
        this.loading = false
      })
    }

    // 新增
    onSetting (row: any) {
      this.showDialog = true
      this.detail = JSON.parse(JSON.stringify(row))
    }

    onHistory (deviceNumber: string) {
      this.$router.push({
        params: { deviceNumber: deviceNumber },
        name: 'smartRecord'
      })
    }
}
