
import { Vue, Component, Prop } from 'vue-property-decorator'
interface Detail {
    deviceNumber: string;
    deviceName: string;
    pressureThreshold: string;
    pressureUpThreshold: string;
}

const threeDecimal = (rule: string, value: string, callback: Function) => {
  const reg = /^([1-9][0-9]*|[0-9])(\.\d{0,3})?$/
  if (value && !reg.test(value)) {
    return callback(new Error('请输入非负数的数字,最多保留三位小数'))
  } else {
    return callback()
  }
}
@Component
export default class SoilDialog extends Vue {
  @Prop({ default: false }) showDialog!: boolean
  @Prop({ default: { deviceNumber: '' } }) detail!: Detail
    private formInfo = {}

    private rules = {
      pressureThreshold: [
        { required: true, message: '请输入压力告警低值', trigger: 'blur' },
        { validator: threeDecimal, trigger: 'blur' }
      ],
      pressureUpThreshold: [
        { required: true, message: '请输入压力告警高值', trigger: 'blur' },
        { validator: threeDecimal, trigger: 'blur' }
      ]
    }

    created () {
      //
    }

    onSubmit () {
      ;(this.$refs.formInfo as any).validate((valid: any) => {
        if (valid) {
          if (this.detail.pressureUpThreshold <= this.detail.pressureThreshold) {
            this.$message.warning('压力告警高值必须大于压力告警低值')
            return false
          }
          this.formInfo = {
            waterMeterInfo: {
              deviceName: this.detail.deviceName,
              pressureThreshold: this.detail.pressureThreshold,
              pressureUpThreshold: this.detail.pressureUpThreshold
            }
          }
          if (this.detail.deviceNumber) {
            this.formInfo = { ...this.formInfo, deviceNumber: [this.detail.deviceNumber] }
          }
          this.$axios.post(this.$apis.smartHouse.updateSqWaterMeterInfoConf, this.formInfo).then((res) => {
            this.formInfo = res
            this.$message.success('设置成功')
            this.$emit('success')
            this.closeDialog()
          })
        }
      })
    }

    closeDialog () {
      this.$emit('update:showDialog', false)
    }
}
