
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { integerWithin, validNumberOne } from '@/utils/validate'
interface Detail {
  deviceNumber: string;
  deviceName: string;
  drought: string;
  saturated: string;
  frozen: string;
  overheated: string;
}
@Component
export default class SoilDialog extends Vue {
@Prop({ default: false }) showDialog!: boolean
@Prop({ default: { deviceNumber: '' } }) detail!: Detail
  private formInfo = {}

  private rules = {
    drought: [
      { required: true, message: '请输入土壤湿度干旱阈值', trigger: 'blur' },
      { validator: integerWithin, trigger: ['blur', 'change'] }
    ], // 土壤湿度干旱阈值/%
    saturated: [
      { required: true, message: '请输入土壤湿度饱和阈值', trigger: 'blur' },
      { validator: integerWithin, trigger: ['blur', 'change'] }
    ], // 土壤湿度饱和阈值/%
    frozen: [
      { required: true, message: '请输入土壤温度冰冻阈值', trigger: 'blur' },
      { validator: validNumberOne, trigger: ['blur', 'change'] }
    ], // 土壤温度冰冻阈值/℃
    overheated: [
      { required: true, message: '请输入土壤温度过热阈值', trigger: 'blur' },
      { validator: validNumberOne, trigger: ['blur', 'change'] }
    ]
  }

  created () {
    //
  }

  onSubmit () {
    ;(this.$refs.formInfo as any).validate((valid: any) => {
      if (valid) {
        if (this.detail.saturated <= this.detail.drought) {
          this.$message.warning('土壤湿度饱和阈值必须大于土壤湿度干旱阈值')
          return false
        }
        if (this.detail.overheated <= this.detail.frozen) {
          this.$message.warning('土壤温度过热阈值必须大于土壤温度冰冻阈值')
          return false
        }
        this.formInfo = {
          soilInfo: {
            deviceName: this.detail.deviceName,
            drought: this.detail.drought,
            saturated: this.detail.saturated,
            frozen: this.detail.frozen,
            overheated: this.detail.overheated
          }
        }
        if (this.detail.deviceNumber) {
          this.formInfo = { ...this.formInfo, deviceNumber: [this.detail.deviceNumber] }
        }
        this.$axios.post(this.$apis.smartHouse.updateSqSoilInfoConf, this.formInfo).then((res) => {
          this.formInfo = res
          this.$message.success('设置成功')
          this.$emit('success')
          this.closeDialog()
        })
      }
    })
  }

  closeDialog () {
    this.$emit('update:showDialog', false)
  }
}
